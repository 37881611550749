import authService from '../../services/users';

export default {
  namespaced: true,

  state: { expired: false, user: JSON.parse(window.localStorage.getItem('user')) },

  mutations: {
    LOGOUT(state, expired) {
      localStorage.removeItem('token-bIbx0cXd');
      localStorage.removeItem('user');
      state.user = {};

      if (expired) {
        state.expired = true;
        setTimeout(() => {
          location.reload();
        }, 3000);
      } else {
        location.reload();
      }
    },
    ADD_USER(state, user) {
      state.user = { ...user };
      window.localStorage.setItem('user', JSON.stringify(state.user));
    },
    USER_ID(state, id) {
      state.userId = id;
    },
  },
  
  actions: {
    logout({ commit }, expired = false) { 
      commit('LOGOUT', expired);
    },
    async user({ commit }) {
      const response = await authService.user();
      commit('ADD_USER', response.data);
    },
    async validate({ commit },) {
      const response = await authService.validate();
      commit('USER_ID', response.data.id);
      return { success: true, role: response.data.role };
    },
  },

  getters: {
    user: state => {
      return state.user;
    },
    userId: state => {
      return state.userId;
    },
    loggedIn: state => {
      return state.user && !!state.user.role;
    },
  }
};
