export default {
  methods: {
    $formatShowDate(date) {
      date = date ? new Date(date) : new Date();
      return (date.toLocaleString('fr-CA', { timeZone: 'America/Merida' }));
    },
    $getDate() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
    },
    $getTimeLocal(date) {
      date = date ? new Date(date) : new Date();
      const timeOffset = date.getTimezoneOffset() * 60*1000;
      return date.getTime() - timeOffset;
    },
  },
};
