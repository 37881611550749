import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import router from './router'
import i18n from './i18n'
import store from './store'

import './mixins';
// main.js
import VueSweetalert2 from 'vue-sweetalert2';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
const customParseFormat = require('dayjs/plugin/customParseFormat');
const localizedFormat = require('dayjs/plugin/localizedFormat');
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime)
dayjs.locale('es');

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import wb from './registerServiceWorker';

Vue.use(VueSweetalert2);

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

// Vue.prototype.$handleError = (error, title) => {
//   if (error.response && [400, 409].includes(error.response.status)) {
//     this.$swal(title, error.response.data.message, 'warning');
//   }
// }
Vue.prototype.$currency = {
  format(amount) {
    const currency = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN',})
    return currency.format(amount/100);
  }
}
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$workbox = wb;


new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
