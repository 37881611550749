/**
 * import and init global mixins
 */

 import Vue from 'vue'

 import currentUser from '../mixins/auth'
 import formatDateTime from '../mixins/formatDateTime'
 import formatDate from '../mixins/formatDate'
 import settings from '../mixins/settings'
 
 Vue.mixin(currentUser)
 Vue.mixin(formatDateTime)
 Vue.mixin(formatDate)
 Vue.mixin(settings)