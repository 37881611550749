import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        themes: {
          light: {
            primary: "#00aff2",
            secondary: "#424242",
            white: "#ffffff",
            grey: "#eeeeee"
          }
        }
      }

});
