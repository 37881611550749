import { apiClient } from '../index';

/**
 * [user description]
 * @return {[type]}          [description]
 */
function getUsers() {
  return apiClient.get('/v1/users/all');
}

/**
 * [login description] Autentifica un usuario en el sistema
 * @param  {[type]} data    [description]
 * @return {[type]}          [description]
 */
 function updatePerfil(data) {
  return apiClient.put('/v1/users/profile/me', data);
}

/**
 * [user description]
 * @return {[type]}          [description]
 */
function user() {
  return apiClient.get('/v1/users/profile/me');
}

function validate() {
  return apiClient.get('/v1/auth/validate');
}

/**
 * [Obtiene todas las configuracione]
 * @return {[type]}          [description]
 */
 function fetchSettings() {
  return apiClient.get('/v1/config');
}

 async function getpdf() {
  const response = await apiClient.get('/v1/pdfs');
  return response;
}

export default { getUsers, updatePerfil, user, validate, fetchSettings, getpdf};

