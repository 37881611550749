import Vue from 'vue';
import Vuex from 'vuex';

import sessionModule from './modules/session';
import uiModule from './modules/ui';
import roomModule from './modules/room';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    session: sessionModule,
    ui: uiModule,
    room: roomModule,
  },
  state: {
    messages: 0,
    settings: {},
  },
  mutations: {
    resetMessages (state) {
      state.messages = 0;
    },
    messages (state) {
      state.messages++
    },
    settings (state, settings) {
      state.settings = { ...settings }
    }
  },
  getters: {
    settings (state) {
      return state.settings;
    }
  }
});
