import { apiClient } from './index';

/**
 * [Crea una noticia]
 * @return {object} data       [description]
 * {
    endpoint: 'string',
    expirationTime: 'string',
    keys: {
      p256dh: 'string',
      auth: 'string',
    },
  }
 * @return {[type]}          [description]
 */
function suscribe(data) {
  return apiClient.post('/v1/notifications/subscribe', data);
}

/**
 * [Obtienen las notificaciones del usuario]
 * @return {[type]}          [description]
 */
 function fetch() {
  return apiClient.get('/v1/notifications');
}

/**
 * [Marca una notificación como leida]
 * @return {[type]}          [description]
 */
 function toReaded(id) {
  return apiClient.patch('/v1/notifications/readed/' + id);
}

export default { suscribe, fetch, toReaded }
