export default {
  namespaced: true,

  state: {
    expired: false, 
    room: {}
   },

  mutations: {
    SET_ROOM(state, room) {
      state.room = { ...room };
    },
  },
  
  actions: {
    async setRoom({ commit }, room) {
      commit('SET_ROOM', room);
    },
  },

  getters: {
    currentRoom: state => {
      return state.room;
    },
    existCurrentRoom: state => {
      return !!state.id;
    },
  }
};